<template>
  <div :class="{ 'header-left-wrap': true, 'header-content-show': isMenuHide }">
    <div class="header-left-bg" @click="handleHide"></div>
    <div
            :class="{ 'header-left-content ': true, 'header-left-content-show': isMenuHide }"
    >
      <div class="header-left-top">
        <div class="header-left-user">
          <router-link
            v-if="userInfo.id"
            :to="{ name: 'Mine' }"
          >
            <img
              :src="
                userInfo.userImg ||
                'https://shijiaoke.oss-cn-beijing.aliyuncs.com/public/not_loaded.jpg'"
              @click="toSignin"
            />
          </router-link>
          <!-- <img src="http://www.bncc.org.cn/static/m/images/logo.jpg" v-else @click="toLogin" /> -->
          <img src="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/72bd4bed20c9be16bbe779d66699e17c.jpg" v-else @click="toLogin" />
        </div>
        <div class="header-left-text">
          <span v-if="userInfo.id">{{ userInfo.nickName }}</span>
          <span @click="toLogin" v-else>未登录</span>
          <p v-if="userInfo.id">{{ userInfo.personalSignature }}</p>
          <p @click="toLogin" v-else>点击登录</p>
        </div>
        <router-link
          class="header-left-sign"
          :to="{ name: 'signIn' }"
          v-if="userInfo.id"
          @click="toSignin"
          >签到</router-link
        >
        <span class="header-left-sign" @click="toLogin" v-else>签到</span>
      </div>
      <div class="header-left-main-wapper">
        <div>
          <ul class="header-left-main" style="border: none">

            <li>
              <router-link @click="handleHide" :to="{ name: 'BnccCategory', query: { type: 0 } }">
                <img
                        style="left: 12px"
                        src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//yp20210419.png"
                />
                <span>微生物菌种</span>
              </router-link>
            </li>
           <li>
              <router-link @click="handleHide" :to="{ name: 'BnccCategory', query: { type: 1 } }">
                <img
                        style="width: 0.6rem"
                        src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//xb20210419.png"
                />
                <span>实验细胞</span>
              </router-link>
            </li>
          </ul>
          <ul class="header-left-main">
            <li>
              <router-link @click="handleHide" :to="{ name: 'News'}">
                <img
                        style="width: 0.6rem"
                        src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//icon1220210419.png"
                />
                <span>新闻资讯</span>
              </router-link>
            </li>
            <li>
              <router-link @click="handleHide" :to="{ name: 'Course' }">
                <img
                        style="width: 0.6rem"
                        src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//icon1320210419.png"
                />
                <span>直播间</span>
              </router-link>
            </li>
            <li>
              <router-link @click="handleHide" :to="{ name: 'Course' }">
                <img
                        style="width: 0.76rem; left: 0.2rem;"
                        src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/Course/peixunzhongxin20210126.png"
                />
                <span>培训中心</span>
              </router-link>
            </li>
            <li>
              <router-link @click="handleHide" :to="{ name: 'Topic' }">
                <img
                        style="width: 0.6rem"
                        src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//fatie20210419.png"
                />
                <span>交流区</span>
              </router-link>
            </li>
            <li>
              <router-link @click="handleHide" :to="{ name: 'Library' }">
                <img
                        style="width: 0.6rem"
                        src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//icon1520210419.png"
                />
                <span>文库</span>
              </router-link>
            </li>
          </ul>
          <ul class="header-left-main">
            <li>
              <router-link @click="handleHide" :to="{ name: 'About'}">
                <img
                        style="width: 0.707rem"
                        src="@/assets/img/icon_phone.svg"
                />
                <span>联系我们</span>
              </router-link>
            </li>
            <li>
              <router-link @click="handleHide" :to="{ name: 'ShopCar'}">
                <img
                        style="width: 0.6rem"
                        src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//icon23-120210419.png"
                />
                <span>购物车</span>
              </router-link>
            </li>
            <li>
              <router-link @click="handleHide" :to="{ name: 'Index'}">
                <img
                        style="width: 0.6rem"
                        src="https://beinabncc.oss-cn-beijing.aliyuncs.com/file//icon21-220210417.png"
                />
                <span>返回主页</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, inject } from "vue";
  import { useRouter, useRoute } from "vue-router";

  export default defineComponent({
    props: {
      isMenuHide: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const hide = inject("handleMenuHide");
      const isHeaderHide = inject("isHeaderHide")
      const router = useRouter();
      const route = useRoute();
      function handleHide() {
        (hide as () => void)();
      }

      //  展示用户信息
      const userInfo = inject("userInfo") as any;
      function toLogin() {
        (hide as () => void)();
        if(route.path != "/login"){
          // router.push({
          //   name: "Login",
          //   query: {
          //     redirect: route.fullPath
          //   }
          // })
          router.push({
            name: "Mine",
          })
        }else{
          // router.push({
          //   name: "Login",
          // })
          router.push({
            name: "Mine",
          })
        }

      }

      function toSignin() {
        (hide as () => void)();
      }

      return {
        handleHide,
        toLogin,
        userInfo,
        toSignin
      };
    },
  });
</script>

<style lang="scss" scoped>
  /* 头部侧边栏弹出窗 start*/
  .header-left-wrap {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 10000;
    overflow: hidden;
    transform: translateX(-100%);
  }
  .header-content-show {
    transform: translateX(0);
  }

  .header-left-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    /* // opacity: 0; */
    opacity: 1;
  }

  .header-left-content {
    width: 274.988px;
    background-color: #fff;
    overflow: hidden;
    position: absolute;
    /* // left: -100%; */
    left: 0;
    transform: translateX(-274.988px);
    z-index: 1000;
    top: 0;
    height: 100%;
    overflow-y: scroll;
    transition: transform 0.3s;
  }
  .header-left-main-wapper {
    height: 80%;
    padding-bottom: 154.988px;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  .header-left-content-show {
    transform: translateX(0);
    transition: transform 0.5s;
  }

  .header-left-top {
    width: 100%;
    height: 105px;
    background-color: #d64b4b;
    box-sizing: border-box;
    padding-left: 10.013px;
    overflow: hidden;
    padding-right: 10.013px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
  }

  .header-left-user {
    display: block;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    overflow: hidden;
    float: left;
  }

  .header-left-user img {
    width: 100%;
    height: 100%;
  }

  .header-left-text {
    width: 115.013px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 15px;
    color: #ffffff;
  }

  .header-left-text span {
    font-size: 16.012px;
    color: #ffffff;
  }

  .header-left-text p {
    width: 100%;
    color: #ffffff;
    font-size: 12px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .header-left-sign {
    display: block;
    width: 67.987px;
    height: 30px;
    border-radius: 15px;
    border: solid 1.012px #ffffff;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    font-size: 13.988px;
    box-sizing: border-box;
  }

  .header-left-main {
    width: 100%;
    margin-top: 4.988px;
    /* padding-top: 10.013px; */
    border-top: 2px solid #f4f4f4;
  }

  .header-left-main li {
    width: 100%;
    height: 42px;
    line-height: 42px;
    box-sizing: border-box;
    padding: 0 10.013px;
    padding-left: 49.987px;
    position: relative;
  }

  .header-left-main img {
    width: 17.512px;
    height: auto;
    border: none;
    position: absolute;
    left: 10.013px;
    top: 50%;
    transform: translateY(-40%);
  }

  .header-left-main li span {
    color: #666666;
    font-size: 16.012px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    top: -2px;
  }
  /* 头部侧边栏弹出窗 end*/
</style>
