
  import { defineComponent, inject } from "vue";
  import { useRouter, useRoute } from "vue-router";

  export default defineComponent({
    props: {
      isMenuHide: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const hide = inject("handleMenuHide");
      const isHeaderHide = inject("isHeaderHide")
      const router = useRouter();
      const route = useRoute();
      function handleHide() {
        (hide as () => void)();
      }

      //  展示用户信息
      const userInfo = inject("userInfo") as any;
      function toLogin() {
        (hide as () => void)();
        if(route.path != "/login"){
          // router.push({
          //   name: "Login",
          //   query: {
          //     redirect: route.fullPath
          //   }
          // })
          router.push({
            name: "Mine",
          })
        }else{
          // router.push({
          //   name: "Login",
          // })
          router.push({
            name: "Mine",
          })
        }

      }

      function toSignin() {
        (hide as () => void)();
      }

      return {
        handleHide,
        toLogin,
        userInfo,
        toSignin
      };
    },
  });
