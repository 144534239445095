export default  {
    "Index": "http://www.bncc.com/",
    "Interest": "http://www.bncc.com/",
    "News": "http://www.bncc.com/nlist/1-1.html",
    "NewsDetail": "http://www.bncc.com/news/n{{newsid}}.html",
    "DocDetail": "http://www.bncc.com/Library/LibraryDetail/{{docId}}.html",
    "Forget": "http://www.bncc.com/",
    "About": "http://www.bncc.com/contact.html",
    "Topic": "http://www.bncc.com/BBS/index.html",
    "TopicDetail": "http://www.bncc.com/bbs/topic_{{topicId}}.html",
    "PostTopic": "http://www.bncc.com/BBS/TopicPosts.html?type=1",
    "product": "http://www.bncc.com/plist/p1-0-1.html",
    "productdetail": "http://www.bncc.com/p_{{classId}}/p_{{id}}.html",
    /*"bnccdetail": "http://www.bncc.com/pro/p8/0/p_{{id}}.html",*/
    "standard": "http://www.bncc.com/slist_3/slist_3_0_0_1.html",
    "customize": "http://www.bncc.com/Product/SearchOpenSearchNo.html",
    "standarddetail": "http://www.bncc.com/s_{{classId}}/s_{{id}}.html",
    "Order": "http://www.bncc.com/User/NeiOrders.html",
    "Invoice": "http://www.bncc.com/AokeNei/GetNoApplyInvoice.html",
    "Intergral": "http://www.bncc.com/User/CreditLogs.html",
    "Exchange": "http://www.bncc.com/User/ExchangeProduct.html",
    "ExchangeDetail": "http://www.bncc.com/",
    "IntergralRule": "http://www.bncc.com/User/Integralrule.html",
    "Bill": "http://www.bncc.com/AokeNei/GetUserAccount.html",
    "UserInfo": "http://www.bncc.com/User/UserInfo.html",
    "FootPrint": "http://www.bncc.com/",
    "Message": "http://www.bncc.com/",
    "Attention": "http://www.bncc.com/{{uid}}/MyFollowUser_1.html",
    "Unsubscribe": "http://www.bncc.com/{{uid}}/FollowMyUser_1.html",
    "Collection": "http://www.bncc.com/User/MyCollection.html",
    "Account": "http://www.bncc.com/User/index.html",
    "ChangeMobile": "http://www.bncc.com/User/BindAccount",
    "ChangePwd": "http://www.bncc.com/User/BindAccount",
    "Prize": "http://www.bncc.com/AokeNei/GetUserPrizes.html",
    "Suggest": "http://www.bncc.com/User/Guestbook.html",
    "ShopCar": "http://www.bncc.com/ShoppingCart",
    "SubmitOrder": "http://www.bncc.com/ShoppingCart/Checkout",
    "OpenSearch": "http://www.bncc.com/",
    "Personal": "http://www.bncc.com/{{uid}}.html",
    "signIn": "http://www.bncc.com/User/index.html",
    "Course": "http://www.bncc.com/Course/index.html",
    "CourseCommentDetail": "http://www.bncc.com/",
    "TopicCommentDetail": "http://www.bncc.com/",
    "videodetail": "http://www.bncc.com/TrainingDetail/{{id}}_1_1.html",
    "livedetail": "http://www.bncc.com/LiveDetail/{{id}}_1_1.html",
    "videoRealtime": "http://www.bncc.com/livePlay/{{id}}.html?type=1",
    "Address": "http://www.bncc.com/User/Address",
    "addAddress": "http://www.bncc.com/User/Address",
    "Chat": "http://www.bncc.com",
    "OrderDetail": "http://www.bncc.com/User/OrderDetail?num={{num}}",
    "NeiOrderDetail": "http://www.bncc.com/User/NeiOrderDetail?ONO={{id}}",
    "Login": "http://www.bncc.com/",
    "InitTel": "http://www.bncc.com/",
    "ApplyInvoice": "http://www.bncc.com/AokeNei/GetNoApplyInvoice.html",
    "Lottery": "http://www.bncc.com/",
    "StartJob": "http://www.bncc.com/",
    "StartJobReal": "http://www.bncc.com/",
    "Home": "http://www.bncc.com/",
    "Mine": "http://www.bncc.com/User/index.html",
    "Search": "http://www.bncc.com/",
    "workStart2024":"http://www.bncc.com/Event/workStart2024",
    "virtuallyInfo":"https://www.bncc.com/User/virtuallyInfoShow/{{id}}.html",
    "Error": "http://www.bncc.com/"
}